import React, { useState } from 'react';
import { Box, Button, Heading, Layer, Text } from 'grommet';

const Sad = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="67" height="60">
      <defs>
        <linearGradient id="a" x1="100%" x2="0%" y1="50%" y2="50%">
          <stop offset="0%" stop-color="#878D06" />
          <stop offset="47.525%" stop-color="#D89206" />
          <stop offset="100%" stop-color="#D15A80" />
        </linearGradient>
      </defs>
      <path
        fill="url(#a)"
        fill-rule="nonzero"
        d="M43 0a15.005 15.005 0 0 1 14.999 15v28a15.005 15.005 0 0 1-15 14.999H15A15.005 15.005 0 0 1 0 42.999V15A15.005 15.005 0 0 1 15 0h28Zm0 5.999H15c-4.97 0-9 4.031-9 9v28c0 4.969 4.03 9 9 9h28c4.968 0 9-4.031 9-9v-28c0-4.969-4.032-9-9-9ZM38 35.71l1.11 1.172-4.24 4.238-1.171-1.172a6.663 6.663 0 0 0-9.41 0l-1.172 1.168-4.239-4.238 1.224-1.168c4.94-4.941 12.952-4.941 17.898 0ZM22 23.499c0 4.668-7 4.668-7 0s7-4.668 7 0Zm21 0c0 4.668-7 4.668-7 0s7-4.668 7 0Z"
      />
    </svg>
  );
};

const Notification = ({ message }) => {
  const [open, setOpen] = useState(true);

  if (!open) return null;

  return (
    <Layer position="top" margin="small" full="horizontal" responsive={false}>
      <Box direction="row-responsive" align="center" pad={{ horizontal: 'small' }}>
        <Box alignSelf="center" margin={{ vertical: 'small' }}>
          <Sad />
        </Box>
        <Box fill margin={{ horizontal: 'small' }}>
          <Heading level={1} size="small">
            Oops! Something went wrong
          </Heading>
          <Text>{message}</Text>
        </Box>
        <Button
          alignSelf="center"
          secondary
          label="Close"
          onClick={() => setOpen(false)}
          margin={{ vertical: 'small' }}
        />
      </Box>
    </Layer>
  );
};

export default Notification;
